import axios from 'axios';

class BrandService {
  list() {
    return axios
      .get('api/brands/')
      .then(response => {
        return response.data;
      });
  }

//   show(brandId) {

//   }
  
}

export default new BrandService();