<template>
  <BaseLayout>
    <main id="content">
      <div class="container">
         <div class="group mb-5">
          <div class="steps mb-4 mb-lg-0">
            <ul class="list-unstyled">
              <li class="active" v-bind:class="{ completed : isBrandSelected }">Brand</li>
              <li>Category</li>
              <li>Sub-Category</li>
              <li>View &amp; Compare</li>
            </ul>
          </div>
        </div>
        <header class="content-header">
          <h1 class="text-primary theme-text">Select Brand</h1>
          <p class="lead">Select all that apply</p>
        </header>
        <div class="content-body">
          <div class="cards d-flex">
            <div class="card" v-bind:class="{ active : (isBrandSelected && selectedBrand.id == brand.id) }" v-for="brand in brands" :key="brand.id" v-on:click="selectBrand(brand)" :id= "sanitizeInput(brand.name) + '-brand-button'">
              <figure class="logo">
                <img :src="brand.logo" :alt="brand.name" />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </main>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/layouts/base";
import BrandService from "@/services/brand.service";

export default {
  name: "SelectBrand",
  components: {
    BaseLayout,
  },
  data: function () {
    return {
      brands: [],
    };
  },
  mounted() {
    this.getBrands();
    this.$store.dispatch("brand/diselect");
    this.$store.dispatch("brand/diselectBrands");
    this.$store.dispatch("category/diselect");
    this.$store.dispatch("subCategory/diselect");
    this.$store.dispatch("filters/remove");

  },
  computed: {
    selectedBrand() {
      return this.$store.state.brand.selectedBrand;
    },
    isBrandSelected() {
      return this.$store.state.brand.selectedBrand != null;
    },
  },
  methods: {
    getBrands: function () {
      BrandService.list().then((list) => {
        this.brands = list;
      });
    },

    selectBrand: function (brand) {
      this.$store.dispatch("brand/select", brand);
      this.$store.dispatch("brand/selectBrands", [brand]);
      this.$router.push("/select-category")
    },

    sanitizeInput: function(input) {
      var sanitizedData = "";
      // Change to lower case
      var inputLower = input.toLowerCase();
      // Letter "e"
      sanitizedData = inputLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
      // Letter "a"
      sanitizedData = sanitizedData.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
      // Letter "o"
      sanitizedData = sanitizedData.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
      // Letter "u"
      sanitizedData = sanitizedData.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
      // Letter "d"
      sanitizedData = sanitizedData.replace(/đ/gi, 'd');
      // Trim the last whitespace
      sanitizedData = sanitizedData.replace(/\s*$/g, '');
      // Change whitespace to "-"
      sanitizedData = sanitizedData.replace(/\s+/g, '-');
      
      return sanitizedData;
    }

  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>