var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseLayout", [
    _c("main", { attrs: { id: "content" } }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "group mb-5" }, [
          _c("div", { staticClass: "steps mb-4 mb-lg-0" }, [
            _c("ul", { staticClass: "list-unstyled" }, [
              _c(
                "li",
                {
                  staticClass: "active",
                  class: { completed: _vm.isBrandSelected }
                },
                [_vm._v("Brand")]
              ),
              _c("li", [_vm._v("Category")]),
              _c("li", [_vm._v("Sub-Category")]),
              _c("li", [_vm._v("View & Compare")])
            ])
          ])
        ]),
        _c("header", { staticClass: "content-header" }, [
          _c("h1", { staticClass: "text-primary theme-text" }, [
            _vm._v("Select Brand")
          ]),
          _c("p", { staticClass: "lead" }, [_vm._v("Select all that apply")])
        ]),
        _c("div", { staticClass: "content-body" }, [
          _c(
            "div",
            { staticClass: "cards d-flex" },
            _vm._l(_vm.brands, function(brand) {
              return _c(
                "div",
                {
                  key: brand.id,
                  staticClass: "card",
                  class: {
                    active:
                      _vm.isBrandSelected && _vm.selectedBrand.id == brand.id
                  },
                  attrs: {
                    id: _vm.sanitizeInput(brand.name) + "-brand-button"
                  },
                  on: {
                    click: function($event) {
                      return _vm.selectBrand(brand)
                    }
                  }
                },
                [
                  _c("figure", { staticClass: "logo" }, [
                    _c("img", { attrs: { src: brand.logo, alt: brand.name } })
                  ])
                ]
              )
            }),
            0
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }